// Color system

$white:    #fff;
$gray-100: #f8f9ff;
$gray-200: #f4f5fd;
$gray-300: #eeeff8;
$gray-400: #e6e7f1;
$gray-500: #dfe0ea;
$gray-600: #d1d2db;
$gray-700: #a6a6b9;
$gray-800: #7a7b97;
$gray-900: #3b3e66;
$black: #070919;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
                (
                        "100": $gray-200,
                        "200": $gray-300,
                        "300": $gray-400,
                        "400": $gray-500,
                        "500": $gray-600,
                        "600": $gray-700,
                        "700": $gray-800,
                        "800": $gray-900,
                        "900": $black
                ),
                $grays
);

$first:   #3c44b1;
$second:  $black;
$blue:    #4191ff;
$indigo:  #7420ff;
$purple:  #793de6;
$pink:    #fc26a4;
$red:     #f83245;
$orange: #f4772e;
$yellow:  #ffc926;
$green:   #1bc943;
$teal:    #18e1a5;
$cyan: #11c5db;

$primary-blue-1: #1273d4;
$primary-blue-2: #222c3c;
$primary-blue-3: #0e59a4;
$primary-blue-4: #2c8ced;

$primary-gray-1: #3f536e;
$primary-gray-2: #7884A5;
$primary-gray-3: #d8e5ee;
$primary-gray-4: #d8d8d8;
$primary-gray-5: #f6f9ff;

$secondary-1: #f9b764;
$secondary-2: #494900;
$secondary-3: #ff9d98;
$secondary-4: #5c0500;
$secondary-5: #6a91d3;
$secondary-6: #000000;
$secondary-7: #5fb97c;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
                (
                        "blue":       $blue,
                        "indigo":     $indigo,
                        "purple":     $purple,
                        "pink":       $pink,
                        "red":        $red,
                        "orange":     $orange,
                        "yellow":     $yellow,
                        "green":      $green,
                        "teal":       $teal,
                        "cyan":       $cyan,
                        "white":      $white,
                        "gray":       $gray-600,
                        "gray-dark":  $gray-800
                ),
                $colors
);

$primary:       $first;
$secondary:     $gray-100;
$success:       $green;
$info:          $cyan;
$warning:       $orange;
$danger:        $red;
$light:         $gray-200;
$dark:          $gray-800;

$pending:       #f89f06;
$refunded:      purple;
$disputed:      #2F5597;
$failed:        $red;
$succeeded:     #429321;

$primary-neutral:       lighten(#d6daee, 6%);
$secondary-neutral:     lighten(#f5f6f7, 6%);
$success-neutral:       lighten(#ccf4dc, 6%);
$info-neutral:          lighten(#ccf7fa, 6%);
$warning-neutral:       lighten(#fff4d8, 5%);
$danger-neutral:        lighten(#ffd6db, 6%);
$light-neutral:         lighten(#fdfdff, 6%);
$dark-neutral:          lighten(#e4e4ea, 6%);
$first-neutral:         lighten(#cce9fe, 6%);
$second-neutral:        lighten(#d0d2da, 6%);

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "succeeded":  $succeeded,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "gray":       $gray-100,
        "dark":       $dark,
        "first":      $blue,
        "second":     $second,
        "pending":    $pending,
        "refunded":   $refunded,
        "disputed":   $disputed,
        "failed":     $failed,
    ),
    $theme-colors
);

$theme-colors-neutral: ();
$theme-colors-neutral: map-merge(
    (
        "primary":    $primary-neutral,
        "secondary":  $secondary-neutral,
        "success":    $success-neutral,
        "info":       $info-neutral,
        "warning":    $warning-neutral,
        "danger":     $danger-neutral,
        "dark":       $dark-neutral,
        "first":      $first-neutral,
        "second":     $second-neutral,
    ),
    $theme-colors-neutral
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900;
$yiq-text-light:            $white;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3)
                ),
                $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
                (
                        25: 25%,
                        50: 50%,
                        75: 75%,
                        100: 100%,
                        auto: auto
                ),
                $sizes
);


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5;
$line-height-sm:              1.5;

$border-width:                1px;
$border-color:                $gray-300;

$border-radius:               .65rem;
$border-radius-lg:            .75rem;
$border-radius-sm:            .29rem;
$border-radius-xs:            .2rem;

$rounded-pill:                50rem;

$basic-box-shadow:            0 0 10px rgba($black, .2);
$basic-box-shadow-light:      0 0 4px rgba($black, .2);
$box-shadow-sm:               0 0.313rem 0.8rem rgba($gray-800,.5), 0 0.126rem 0.225rem rgba($gray-800,.3);
$box-shadow-sm-light:         0 0.46875rem 2.1875rem rgba($gray-900,.03), 0 0.9375rem 1.40625rem rgba($gray-900,.03), 0 0.25rem 0.53125rem rgba($gray-900,.05), 0 0.125rem 0.1875rem rgba($gray-900,.03);
$box-shadow-sm-light-up:      0 -0.46875rem 2.1875rem rgba($gray-900,.03), 0 -0.9375rem 1.40625rem rgba($gray-900,.03), 0 -0.25rem 0.53125rem rgba($gray-900,.05), 0 -0.125rem 0.1875rem rgba($gray-900,.03);
$box-shadow-sm-above:         0 -0.313rem 0.8rem rgba($gray-800,.5), 0 -0.126rem 0.225rem rgba($gray-800,.3);
$box-shadow:                  0 .5rem 1rem rgba($black, .55);
$box-shadow-lg:               0 1rem 3rem rgba($black, .575);
$box-shadow-sm-dark:          0 0.313rem 0.8rem rgba($black,.3), 0 0.126rem 0.225rem rgba($black,.1);
$box-shadow-xxl:              0 0.180rem .3rem rgba($gray-700,.5), 0 .326rem 3rem rgba($gray-800,.3);

$component-active-color:      $white;
$component-active-bg:         theme-color("primary");

$caret-width:                 .3em;
$caret-vertical-align:        $caret-width * .85;
$caret-spacing:               $caret-width * .85;

$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;

$embed-responsive-aspect-ratios: ();
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
                        (
                                        (21 9),
                                        (16 9),
                                        (4 3),
                                        (1 1),
                        ),
                $embed-responsive-aspect-ratios
);


// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Heebo', sans-serif;
$font-family-monospace:       "Times New Roman", Times, serif;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case
$font-size-base:              .95rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;
$font-size-xs:                $font-size-base;
$font-size-xl:                $font-size-base * 1.35;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      $spacer / 2;
$headings-font-family:        null;
$headings-font-weight:        500;
$headings-line-height:        1.2;
$headings-color:              null;

$display1-size:               3.5rem;
$display2-size:               3rem;
$display3-size:               2.5rem;
$display4-size:               1.7rem;

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;
$display-line-height:         $headings-line-height;

$text-muted:                  #222C3C;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   #F6F9FE;
$body-color:                #222D3C;


// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1281px
);
