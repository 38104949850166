@import "variables";
// stylelint-disable property-blacklist, scss/dollar-variable-default

// SCSS RFS mixin
//
// Automated font-resizing
//
// See https://github.com/twbs/rfs

// Configuration

// Base font size
$rfs-base-font-size: 1.25rem !default;
$rfs-font-size-unit: rem !default;

// Breakpoint at where font-size starts decreasing if screen width is smaller
$rfs-breakpoint: 1200px !default;
$rfs-breakpoint-unit: px !default;

// Resize font-size based on screen height and width
$rfs-two-dimensional: false !default;

// Factor of decrease
$rfs-factor: 10 !default;

@if type-of($rfs-factor) != "number" or $rfs-factor <= 1 {
  @error "`#{$rfs-factor}` is not a valid  $rfs-factor, it must be greater than 1.";
}

// Generate enable or disable classes. Possibilities: false, "enable" or "disable"
$rfs-class: false !default;

// 1 rem = $rfs-rem-value px
$rfs-rem-value: 16 !default;

// Safari iframe resize bug: https://github.com/twbs/rfs/issues/14
$rfs-safari-iframe-resize-bug-fix: false !default;

// Disable RFS by setting $enable-responsive-font-sizes to false
$enable-responsive-font-sizes: true !default;

// Cache $rfs-base-font-size unit
$rfs-base-font-size-unit: unit($rfs-base-font-size);

// Remove px-unit from $rfs-base-font-size for calculations
@if $rfs-base-font-size-unit == "px" {
  $rfs-base-font-size: $rfs-base-font-size / ($rfs-base-font-size * 0 + 1);
}
@else if $rfs-base-font-size-unit == "rem" {
  $rfs-base-font-size: $rfs-base-font-size / ($rfs-base-font-size * 0 + 1 / $rfs-rem-value);
}

// Cache $rfs-breakpoint unit to prevent multiple calls
$rfs-breakpoint-unit-cache: unit($rfs-breakpoint);

// Remove unit from $rfs-breakpoint for calculations
@if $rfs-breakpoint-unit-cache == "px" {
  $rfs-breakpoint: $rfs-breakpoint / ($rfs-breakpoint * 0 + 1);
}
@else if $rfs-breakpoint-unit-cache == "rem" or $rfs-breakpoint-unit-cache == "em" {
  $rfs-breakpoint: $rfs-breakpoint / ($rfs-breakpoint * 0 + 1 / $rfs-rem-value);
}

// Responsive font-size mixin
@mixin rfs($fs, $important: false) {
  // Cache $fs unit
  $fs-unit: if(type-of($fs) == "number", unit($fs), false);

  // Add !important suffix if needed
  $rfs-suffix: if($important, " !important", "");

  // If $fs isn't a number (like inherit) or $fs has a unit (not px or rem, like 1.5em) or $ is 0, just print the value
  @if not $fs-unit or $fs-unit != "" and $fs-unit != "px" and $fs-unit != "rem" or $fs == 0 {
    font-size: #{$fs}#{$rfs-suffix};
  }
  @else {
    // Variables for storing static and fluid rescaling
    $rfs-static: null;
    $rfs-fluid: null;

    // Remove px-unit from $fs for calculations
    @if $fs-unit == "px" {
      $fs: $fs / ($fs * 0 + 1);
    }
    @else if $fs-unit == "rem" {
      $fs: $fs / ($fs * 0 + 1 / $rfs-rem-value);
    }

    // Set default font-size
    @if $rfs-font-size-unit == rem {
      $rfs-static: #{$fs / $rfs-rem-value}rem#{$rfs-suffix};
    }
    @else if $rfs-font-size-unit == px {
      $rfs-static: #{$fs}px#{$rfs-suffix};
    }
    @else {
      @error "`#{$rfs-font-size-unit}` is not a valid unit for $rfs-font-size-unit. Use `px` or `rem`.";
    }

    // Only add media query if font-size is bigger as the minimum font-size
    // If $rfs-factor == 1, no rescaling will take place
    @if $fs > $rfs-base-font-size and $enable-responsive-font-sizes {
      $min-width: null;
      $variable-unit: null;

      // Calculate minimum font-size for given font-size
      $fs-min: $rfs-base-font-size + ($fs - $rfs-base-font-size) / $rfs-factor;

      // Calculate difference between given font-size and minimum font-size for given font-size
      $fs-diff: $fs - $fs-min;

      // Base font-size formatting
      // No need to check if the unit is valid, because we did that before
      $min-width: if($rfs-font-size-unit == rem, #{$fs-min / $rfs-rem-value}rem, #{$fs-min}px);

      // If two-dimensional, use smallest of screen width and height
      $variable-unit: if($rfs-two-dimensional, vmin, vw);

      // Calculate the variable width between 0 and $rfs-breakpoint
      $variable-width: #{$fs-diff * 100 / $rfs-breakpoint}#{$variable-unit};

      // Set the calculated font-size.
      $rfs-fluid: calc(#{$min-width} + #{$variable-width}) #{$rfs-suffix};
    }

    // Rendering
    @if $rfs-fluid == null {
      // Only render static font-size if no fluid font-size is available
      font-size: $rfs-static;
    }
    @else {
      $mq-value: null;

      // RFS breakpoint formatting
      @if $rfs-breakpoint-unit == em or $rfs-breakpoint-unit == rem {
        $mq-value: #{$rfs-breakpoint / $rfs-rem-value}#{$rfs-breakpoint-unit};
      }
      @else if $rfs-breakpoint-unit == px {
        $mq-value: #{$rfs-breakpoint}px;
      }
      @else {
        @error "`#{$rfs-breakpoint-unit}` is not a valid unit for $rfs-breakpoint-unit. Use `px`, `em` or `rem`.";
      }

      @if $rfs-class == "disable" {
        // Adding an extra class increases specificity,
        // which prevents the media query to override the font size
        &,
        .disable-responsive-font-size &,
        &.disable-responsive-font-size {
          font-size: $rfs-static;
        }
      }
      @else {
        font-size: $rfs-static;
      }

      @if $rfs-two-dimensional {
        @media (max-width: #{$mq-value}), (max-height: #{$mq-value}) {
          @if $rfs-class == "enable" {
            .enable-responsive-font-size &,
            &.enable-responsive-font-size {
              font-size: $rfs-fluid;
            }
          }
          @else {
            font-size: $rfs-fluid;
          }

          @if $rfs-safari-iframe-resize-bug-fix {
            // stylelint-disable-next-line length-zero-no-unit
            min-width: 0vw;
          }
        }
      }
      @else {
        @media (max-width: #{$mq-value}) {
          @if $rfs-class == "enable" {
            .enable-responsive-font-size &,
            &.enable-responsive-font-size {
              font-size: $rfs-fluid;
            }
          }
          @else {
            font-size: $rfs-fluid;
          }

          @if $rfs-safari-iframe-resize-bug-fix {
            // stylelint-disable-next-line length-zero-no-unit
            min-width: 0vw;
          }
        }
      }
    }
  }
}

// The font-size & responsive-font-size mixin uses RFS to rescale font sizes
@mixin font-size($fs, $important: false) {
    @include rfs($fs, $important);
}

@mixin responsive-font-size($fs, $important: false) {
    @include rfs($fs, $important);
}

@mixin text-truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


@mixin text-emphasis-variant($parent, $color, $ignore-warning: false) {
    #{$parent} {
      color: $color !important;
    }
    @if $emphasized-link-hover-darken-percentage != 0 {
      a#{$parent} {
        @include hover-focus() {
          color: darken($color, $emphasized-link-hover-darken-percentage) !important;
        }
      }
    }
  }

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
  }
  
  // Minimum breakpoint width. Null for the smallest (first) breakpoint.
  //
  //    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    576px
  @function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
  }
  
  // Maximum breakpoint width. Null for the largest (last) breakpoint.
  // The maximum value is calculated as the minimum of the next one less 0.02px
  // to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
  // See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
  // Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
  // See https://bugs.webkit.org/show_bug.cgi?id=178261
  //
  //    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    767.98px
  @function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $next: breakpoint-next($name, $breakpoints);
    @return if($next, breakpoint-min($next, $breakpoints) - .02, null);
  }
  
  // Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
  // Useful for making responsive utilities.
  //
  //    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    ""  (Returns a blank string)
  //    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    "-sm"
  @function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
  }
  
  // Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
  // Makes the @content apply to the given breakpoint and wider.
  @mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
      @media (min-width: $min) {
        @content;
      }
    } @else {
      @content;
    }
  }
  
  // Media of at most the maximum breakpoint width. No query for the largest breakpoint.
  // Makes the @content apply to the given breakpoint and narrower.
  @mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
      @media (max-width: $max) {
        @content;
      }
    } @else {
      @content;
    }
  }
  
  // Media that spans multiple breakpoint widths.
  // Makes the @content apply between the min and max breakpoints
  @mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($lower, $breakpoints);
    $max: breakpoint-max($upper, $breakpoints);
  
    @if $min != null and $max != null {
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    } @else if $max == null {
      @include media-breakpoint-up($lower, $breakpoints) {
        @content;
      }
    } @else if $min == null {
      @include media-breakpoint-down($upper, $breakpoints) {
        @content;
      }
    }
  }
  
  // Media between the breakpoint's minimum and maximum widths.
  // No minimum for the smallest breakpoint, and no maximum for the largest one.
  // Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
  @mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    $max: breakpoint-max($name, $breakpoints);
  
    @if $min != null and $max != null {
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    } @else if $max == null {
      @include media-breakpoint-up($name, $breakpoints) {
        @content;
      }
    } @else if $min == null {
      @include media-breakpoint-down($name, $breakpoints) {
        @content;
      }
    }
  }
  

  // Hover mixin and `$enable-hover-media-query` are deprecated.
//
// Originally added during our alphas and maintained during betas, this mixin was
// designed to prevent `:hover` stickiness on iOS-an issue where hover styles
// would persist after initial touch.
//
// For backward compatibility, we've kept these mixins and updated them to
// always return their regular pseudo-classes instead of a shimmed media query.
//
// Issue: https://github.com/twbs/bootstrap/issues/25195

@mixin hover() {
    &:hover { @content; }
  }
  
  @mixin hover-focus() {
    &:hover,
    &:focus {
      @content;
    }
  }
  
  @mixin plain-hover-focus() {
    &,
    &:hover,
    &:focus {
      @content;
    }
  }
  
  @mixin hover-focus-active() {
    &:hover,
    &:focus,
    &:active {
      @content;
    }
  }
  
  // CSS image replacement
@mixin text-hide($ignore-warning: false) {
    // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }

// typography

@mixin header1() {
  font-family: $font-family-base;
  font-size: 26px;
  font-weight: normal;
  color: $primary-blue-2;
}

@mixin header4() {
  font-family: $font-family-base;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1.65px;
  color: $primary-gray-2;
}

@mixin header5() {
  font-family: $font-family-base;
  font-size: 9px !important;
  font-weight: bold !important;
  letter-spacing: .75px !important;
  color: $primary-blue-2;
}

@mixin paragraphBold() {
  font-family: $font-family-base;
  font-size: 12px;
  font-weight: normal;
  color: $primary-blue-2;
}

@mixin paragraphLight() {
  font-family: $font-family-base;
  font-size: 12px;
  font-weight: lighter;
  color: $primary-blue-2;
}
