
// Core
@import "core/mixins";
@import "core/variables";

// Fonts
@import "fonts/fonts";

// Utilities
@import "utilities/text";
@import "utilities/helpers";

// Font settings
* {
  font-family: $font-family-base !important;
  line-height: $line-height-base;
  text-align: left; // 3
}

// body and container settings
body {
  margin: 80px 0 0 0; // 1
  padding-top: 20px;
  color: $body-color;
  background-color: $body-bg; // 2
  @include font-size($font-size-base);
  font-weight: $font-weight-base;
}

.app-container {
    max-width: 1370px !important;
    margin: 0 auto !important;
}

// status colors
.pending-status {
  background-color: $secondary-1 !important;
  color: $secondary-2 !important;
}

.disputed-status {
  background-color: $secondary-5 !important;
  color: $secondary-6 !important;
}

.succeeded-status, .refunded-status, .paid-status {
  background-color: $primary-gray-3 !important;
  color: $primary-gray-1 !important;
}

.failed-status, .returned-status, .refundfailed-status {
  background-color: $secondary-3 !important;
  color: $secondary-4 !important;
}

.released-status {
  background-color: $secondary-7 !important;
  color: $secondary-6 !important;
}

// popper
.popper {
  z-index: 99999;
}
.popper[x-placement*="bottom"] .arrow {
  top: 0;
  left: 0;
  width: 3em;
  height: 1em;
  margin-top: -0.9em;
}
.popper[x-placement*="top"] .arrow {
  left: 0;
  width: 3em;
  bottom: 0;
  height: 1em;
  margin-bottom: -0.9em;
}
.popper[x-placement*="right"] .arrow {
  left: 0;
  width: 1em;
  height: 3em;
  margin-left: -0.9em;
}
.popper[x-placement*="left"] .arrow {
  right: 0;
  width: 1em;
  height: 3em;
  margin-right: -0.9em;
}
.popper[x-placement*="left"] .arrow::before {
  border-color: transparent transparent transparent $white;
  border-width: 1em 0 1em 1em;
}
.popper[x-placement*="right"] .arrow::before {
  border-color: transparent $white transparent transparent;
  border-width: 1em 1em 1em 0;
}
.popper[x-placement*="top"] .arrow::before {
  border-color: $white transparent transparent transparent;
  border-width: 1em 1em 0 1em;
}
.popper[x-placement*="bottom"] .arrow::before {
  border-color: transparent transparent $white transparent;
  border-width: 0 1em 1em 1em;
}
.arrow {
  width: 3em;
  height: 3em;
  position: absolute;
  font-size: 7px;
}
.arrow:before {
  width: 0;
  height: 0;
  margin: auto;
  content: "";
  display: block;
  border-style: solid;
}

// Titles
.app-title {
  color: #222C3C !important;
  font-family: 'Heebo', sans-serif !important;
  font-size: 26px !important;
  font-weight: 400 !important;
}

// Titles
.app-sub-title {
  color: #222C3C !important;
  font-family: 'Heebo', sans-serif !important;
  font-size: 22px !important;
  font-weight: 400 !important;
}


// Loading component
.loading {
  background-color: red;
  height: 5px;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 999999 !important;
}


// Invalid input
input[disabled],
input[readonly],
select[disabled],
select[readonly],
textarea[disabled],
textarea[readonly],
.unauthorized {
  cursor: not-allowed;
}

.MuiInput-underline:before {
  border: none!important;
}

.MuiInput-underline:after {
  border: none!important;
}

.MuiSelect-input.Mui-disabled,
.MuiSelect-select.Mui-disabled {
  cursor: not-allowed !important;
  opacity: .5 !important;
}

// Screen overlay
.screen-overlay {
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity:0;
  width:100%;
  visibility:hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition:opacity .2s linear, visibility .1s, width 1s ease-in;
}

.screen-overlay.show {
  transition:opacity .5s ease, width 0s;
  opacity:1;
  width:100%;
  visibility:visible;
}

.modal{
  visibility: hidden;
  transform:translateX(0%);
  border-radius:0;
  display:block;
  position: fixed;
  top: 0;
  right:0;
  height: 100%;
  z-index: 1200;
  width:100%;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: visibility .2s ease-in-out, transform .2s ease-in-out;
}

.modal.show {
  visibility: visible;
  transform: translateX(0%);
}

@media screen and (min-width: 992px) {
  .modal {
    transform:translateX(56%);
    width: 65%;

    &.small {
      transform:translateX(123%);
      width: 45%;

      &.show {
        visibility: visible;
        transform: translateX(123%);
      }
    }

    &.show {
      visibility: visible;
      transform: translateX(56%);
    }
  }
}


@media screen and (min-width: 759px) {
  .modal {
    &.small {
      transform: translateX(123%);
      width: 45%;

      &.show {
        visibility: visible;
        transform: translateX(123%);
      }
    }
  }
}

.modal-content {
  margin: 0;
  padding-top: 20px;
  color: #222D3C;
  background-color: #F6F9FE;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  &.white {
    background-color: #FFFFFF !important;
  }
}

.frontsteps-primary-btn {
  background-color: #1273D4;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.25);
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 4px 8px;
}

.frontsteps-success-btn {
  background-color: $success;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.25);
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 4px 8px;
}

.frontsteps-success-btnhover {
  background-color: $succeeded;
}

.frontsteps-primary-btn:hover {
  background-color: #2C8CED;
}

.frontsteps-primary-btn:active {
  background-color: #0E59A4;
}

.frontsteps-primary-btn:disabled, .frontsteps-primary-btn[readonly] {
  background-color: #D8D8D8 !important;
  border: none;
  cursor: not-allowed;
}

.frontsteps-secondary-btn {
  background-color: #FFFFFF;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.25);
  border-radius: 3px;
  color: #3F536E;
  font-size: 14px;
  text-align: center;
  padding: 8px 16px;
}

.frontsteps-secondary-btn:hover {
  border: 1px solid #2C8CED;
  background-color: #FFFFFF;
  color: #2C8CED;
}

.frontsteps-secondary-btn:active {
  border: 1px solid #0E59A4;
  background-color: #FFFFFF;
  color: #0E59A4;
}

.frontsteps-secondary-btn:disabled, .frontsteps-primary-btn[readonly] {
  background-color: #FFFFFF !important;
  border: 1px solid #BFBFBF;
  color: #BFBFBF;
  cursor: not-allowed;
}

.scheduledControls, .scheduledControlsDivider {
  display: none !important;
}

// Multiselect overwritten css
.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 36px !important; /* Height */
}

.dropdown-content {
  z-index: 9999999 !important;
}

.MuiSelect-select:focus,
.MuiSelect-input:focus{
  outline-style: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
  background-color: transparent !important;
}
